import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import NavItem from './NavItem'

function NavigationDropDownItem({ items, name, userName }) {
    const [isHovered, setIsHovered] = useState(false)

    const fadeInOut = {
        hidden: { opacity: 0, y: 0 },
        visible: { opacity: 1, y: 0 },
    }

    return (
        <div
            className="relative py-2 items-center flex !mx-0 dark:text-white text-black"
            onMouseEnter={() => {
                setIsHovered(true)
            }}
            onMouseLeave={() => setIsHovered(false)}
        >
            <button
                className="cursor-pointer font-semibold flex items-center 2xl:text-[16px] xl:text-[14px] text-[12px] hover:text-[#FFC30B] !mx-0"
                aria-haspopup="true"
            >
                {name}
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    className="2xl:w-4 w-3 2xl:h-4 h-3 ml-2 dark:text-white text-black"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                </svg>
            </button>
            <AnimatePresence>
                {isHovered && (
                    <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="hidden"
                        variants={fadeInOut}
                        transition={{ duration: 0.1, ease: 'easeInOut' }}
                        className="absolute lg:right-[-200%] top-[40px] z-50 min-w-[560px]"
                    >
                        <div className="p-4  bg-white dark:!bg-zinc-900 rounded-xl shadow-xl w-full border border-b border-zinc-200 dark:!border-zinc-800">
                            <div className="relative z-10">
                                <ul className="grid grid-cols-2 gap-2 text-[15px]">
                                    {items.map((item) =>
                                        item.name !== 'Admin' ||
                                        (userName &&
                                            [
                                                'frcqu.wam',
                                                't1.5c.wam',
                                                'tgz5k.wam',
                                                'scfay.wam',
                                                'nonoswax.gm',
                                            ].includes(userName)) ? (
                                            <NavItem item={item} />
                                        ) : null,
                                    )}
                                </ul>
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    )
}

export default NavigationDropDownItem

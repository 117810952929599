import { Dialog, Disclosure } from '@headlessui/react'
import { ChevronDownIcon, XMarkIcon } from '@heroicons/react/24/outline'
import cn from 'classnames'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Link from '../common/util/input/Link'
import { MobileNavItem } from './MobileNavItem'

function MobileNavigationMenu({
    mobileMenuOpen,
    setMobileMenuOpen,
    market,
    pfpf,
    crafts,
    bulk,
    more,
    userName,
}) {
    const { t } = useTranslation('common')

    return (
        <Dialog
            as="div"
            className="lg:hidden"
            open={mobileMenuOpen}
            onClose={setMobileMenuOpen}
        >
            <div className="z-10" />
            <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white dark:!bg-zinc-900 px-4 py-4 sm:max-w-sm sm:ring-1 sm:ring-white/10 ">
                <div className="flex items-center justify-end">
                    <button
                        type="button"
                        className="-m-2.5 rounded-md p-2.5 text-zinc-900 dark:!text-white"
                        onClick={() => setMobileMenuOpen(false)}
                    >
                        <span className="sr-only">Close menu</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                </div>
                <div className="mt-2 flow-root text-[14px]">
                    <div className="-my-6 divide-y divide-gray-500/10">
                        <div className="space-y-1 py-6 flex flex-col">
                            <Link
                                href="/collections"
                                className={`-mx-3 px-3 py-2 font-sans font-semibold leading-7 text-zinc-900 hover:bg-zinc-200 dark:!text-white hover:dark:!bg-zinc-800 border-b dark:!border-zinc-800 border-zinc-200`}
                            >
                                {t('navigation.collections')}
                            </Link>
                            <Disclosure
                                defaultOpen={true}
                                as="div"
                                className="-mx-3 flex flex-col border-b border-zinc-200 dark:!border-zinc-800"
                            >
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button
                                            className={`flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 font-sans font-semibold leading-7 text-zinc-900 dark:!text-white hover:bg-zinc-200 hover:dark:!bg-zinc-800`}
                                        >
                                            {t('landing.market_title')}

                                            <ChevronDownIcon
                                                className={cn(
                                                    open ? 'rotate-180' : '',
                                                    'h-5 w-5 flex-none',
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="mt-2 space-y-2">
                                            {[...market].map((item) => (
                                                <MobileNavItem item={item} />
                                            ))}
                                            <div className={'mb-4'} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure
                                as="div"
                                className="-mx-3 flex flex-col border-b border-zinc-200 dark:!border-zinc-800"
                            >
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button
                                            className={`flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 font-sans font-semibold leading-7 text-zinc-900 dark:!text-white hover:bg:zinc-200 hover:dark:!bg-zinc-800`}
                                        >
                                            {t('navigation.pfps')}
                                            <ChevronDownIcon
                                                className={cn(
                                                    open ? 'rotate-180' : '',
                                                    'h-5 w-5 flex-none',
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="mt-2 space-y-2">
                                            {[...pfpf].map((item) => (
                                                <MobileNavItem item={item} />
                                            ))}
                                            <div className={'mb-4'} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure
                                as="div"
                                className="-mx-3 flex flex-col border-b border-zinc-200 dark:!border-zinc-800"
                            >
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button
                                            className={`flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 font-sans font-semibold leading-7 text-zinc-900 dark:!text-white hover:bg:zinc-200 hover:dark:!bg-zinc-800`}
                                        >
                                            {t('navigation.crafting')}
                                            <ChevronDownIcon
                                                className={cn(
                                                    open ? 'rotate-180' : '',
                                                    'h-5 w-5 flex-none',
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="mt-2 space-y-2">
                                            {[...crafts].map((item) => (
                                                <MobileNavItem item={item} />
                                            ))}
                                            <div className={'mb-4'} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Link
                                href="/creator"
                                className={`-mx-3 block px-3 py-2 font-sans font-semibold leading-7 text-zinc-900 dark:!text-white  hover:bg-zinc-200 hover:dark:!bg-zinc-800 border-b border-zinc-200 dark:!border-zinc-800`}
                            >
                                {t('navigation.creator')}
                            </Link>
                            <Disclosure
                                as="div"
                                className="-mx-3  border-b border-zinc-200 dark:!border-zinc-800"
                            >
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button
                                            className={`flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 font-sans font-semibold leading-7 text-zinc-900 dark:!text-white  hover:bg-zinc-200 hover:dark:!bg-zinc-800`}
                                        >
                                            {t('navigation.bulk_tools')}
                                            <ChevronDownIcon
                                                className={cn(
                                                    open ? 'rotate-180' : '',
                                                    'h-5 w-5 flex-none',
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="mt-2 space-y-2">
                                            {[...bulk].map((item) => (
                                                <MobileNavItem item={item} />
                                            ))}
                                            <div className={'mb-4'} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                            <Disclosure
                                as="div"
                                className="-mx-3 border-b border-zinc-200 dark:!border-zinc-800"
                            >
                                {({ open }) => (
                                    <>
                                        <Disclosure.Button
                                            className={`flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 font-sans font-semibold leading-7 text-zinc-900 dark:!text-white  hover:bg-zinc-200 hover:dark:!bg-zinc-800`}
                                        >
                                            {t('navigation.more')}
                                            <ChevronDownIcon
                                                className={cn(
                                                    open ? 'rotate-180' : '',
                                                    'h-5 w-5 flex-none',
                                                )}
                                                aria-hidden="true"
                                            />
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="mt-2 space-y-2">
                                            {more.map((item) =>
                                                item.name !== 'Admin' ||
                                                (userName &&
                                                    [
                                                        'frcqu.wam',
                                                        't1.5c.wam',
                                                        'tgz5k.wam',
                                                        'scfay.wam',
                                                        'nonoswax.gm',
                                                    ].includes(userName)) ? (
                                                    <MobileNavItem
                                                        item={item}
                                                    />
                                                ) : null,
                                            )}
                                            <div className={'mb-4'} />
                                        </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        </div>
                    </div>
                </div>
            </Dialog.Panel>
        </Dialog>
    )
}

export default MobileNavigationMenu
